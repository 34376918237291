.termsOfUse {
  width: 100%;
  padding: 50px 11vw;
  margin-top: -24px;
  &__inner {
    position: relative;
    display: flex;
    gap: 70px;
    &_text-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &.scrolled {
        width: 70%;
      }

      h1 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
        margin: 0;
        margin-bottom: 8px;
      }
      .all-articles {
        display: flex;
        flex-direction: column;
        gap: 16px;
        article {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
      p {
        font-family: $gs-regular;
        font-style: normal;
        // font-size: 14px;
        font-size: 0.95em;
        line-height: 160%;
        color: #000000;
        margin: 0;
      }
      h3 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
        margin: 0;
      }
      h6 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin: 0;
      }
    }
    &_content {
      display: flex;
      flex-direction: column;
      padding: 24px 16px 32px;
      gap: 16px;
      width: 230px;
      min-width: 230px;
      max-width: 230px;
      height: fit-content;
      background: #ffffff;
      border: 0.5px solid #b1b1b1;
      border-radius: 12px;
      &.sticky {
        margin-top: -75px;
        position: fixed;
        right: 11%;
      }
      p {
        font-family: $gs-medium;
        font-style: normal;
        // font-size: 14px;
        font-size: 0.95em;
        line-height: 144%;
        margin: 0;
        color: #000000 !important;
      }
      a {
        font-family: $gs-medium;
        font-style: normal;
        // font-size: 14px;
        font-size: 0.95em;
        line-height: 144%;
        margin: 0;
        color: $primaryTextColor;
        text-transform: uppercase;
        &:hover {
          color: #133E87;
        }
      }
    }
  }
}

//responsive design
@media screen and (max-width: 1024px) {
  .termsOfUse {
    &__inner {
      flex-direction: column-reverse;
    }
  }
}
