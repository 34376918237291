.sp-card__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

    @media (max-width: 600px) {
      gap: 4px;
      justify-content: space-evenly;
    }
  }
  
  .sp-card__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  
    .sp-card__icon {
      width: 72px;
      height: 56px;
      object-fit: cover;
      border-radius: 10px;
      border: 1px solid transparent;
      box-shadow: 0px 8px 20px 0px #B1B1B11A;
      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    
      &.active {
        border-color: #133E87;
      }
    }
  }

  .sp-card__title {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    text-align: center;
  }
  

  .sp-card__checkmark {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    background-color: #318D1F;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40%;
    line-height: 1;
  }