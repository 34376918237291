.radio_card {
  margin-top: 20px;
  width: 100%;

  &__wrapper {
    border: 1px solid #F4F8FF;
    padding: 15px;
    border-radius: 12px;
    max-width: 45em;
    cursor: pointer;
    position: relative;
    margin-top: 20px;
  }

  &__selected {
    border: 1px $primaryTextColor solid;

    & label {
      font-family: $gs-medium;
      font-style: normal;
      // font-weight: 600;
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    > .ant-radio-wrapper {
      position: absolute;
      right: 0;
    }
    > label {
      margin-left: 15px;
    }
    > ion-icon {
      position: absolute;
      font-size: 1.5em;
      color: green;
      right: 10px;
    }
  }
}
