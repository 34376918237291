.homepage {
  background-color: $white !important;
  // margin-inline: 0px !important;

  &__img {
    width: 90%;
  }
  &__container {
    margin: 0vw 4.86vw;

    &__row-one {
      padding: 25px;
    }
    &__row-two {
      display: flex;
      align-items: flex-end;
    }

    &__bookmark {
      margin-left: 2px;
      margin-top: 32px;
    }

    @media (max-width: 600px) {
      &__bookmark {
        margin-left: 12px;
        margin-top: 32px;
      }
    }
  }
  &_content__img_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__title {
    font-size: 32px;
    font-weight: 800;
    width: 100%;
    max-width: 560px;
    line-height: 54px;
    margin-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      font-size: 28px;
      max-width: 100%;
      line-height: 120%;
      margin-top: 24px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
   
}

// .service_tile {
//   // &__wrapper {
//   //   display: grid;
//   //   grid-template-columns: repeat(3, 1fr);
//   //   // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   //   // grid-template-columns: repeat(3, minmax(200px, 1fr));
//   //   gap: 10px;
//   //   grid-gap: 20px;
//   //   grid-auto-rows: minmax(100px, auto);
//   // }
// }
