.button {
  // width: 100%;
  // min-width: 30px;
  // max-width: 150px;
  height: 52px;
  border-radius: 30px;
  padding-inline: 40px !important;
  box-shadow: none !important;

  &__sm {
    padding-inline: 20px !important;
  }

  & span {
    font-family: $gs-medium;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
  }

  &__primary {
    background: #133E87;
    &:hover {
      background: #133E87e7 !important;
    }
  }

  &__transparent {
    background: $bgColor;
    border: none;

    &:hover {
      background: #F4F8FFd9 !important;
    }
    & span {
      color: $lceeBlack;
    }
  }

  &__cancel-light {
    background: #feeceb;

    &:hover {
      background: #eed3d2 !important;
    }
    & span {
      color: #f04438;
    }
  }

  &__cancel-dark {
    background: $error;

    &:hover {
      background: #de3f33 !important;
    }
    & span {
      color: $white;
    }
  }

  &__bordered {
    border: 1px #133E87 solid;
    background: transparent;

    &:hover {
      background: #F4F8FFd9 !important;
    }
    & span {
      color: #133E87e7;
    }
  }
}

.button:disabled {
  background: #133E8797;

  &:hover {
    background: #133E878e !important;
  }
}
