.bill_summary {
  // float: left;
  position: relative;
  // width: 35vw;
  // width: 60%;
  max-width: 30rem;

  &__content {
    position: absolute;
    padding-inline: 30px;
    height: 100%;
    width: 100%;
    display: flex;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    & label {
      font-family: $gs-regular;
      font-style: normal;
      font-size: 11px;
      // font-size: 0.75vw;
      line-height: 3em;
      color: #898989;
      text-transform: uppercase;
    }

    &_details {
      font-family: $gs-medium;
      // font-size: 18px;
      font-size: 1.3em;
      color: #121020;
    }

    &_right {
      position: absolute;
      right: 30px;
      // top: 30px;
      justify-content: space-around;
      & img {
        // width: 5vw;
        // height: 5vw;
        width: 72px;
        height: 72px;
        border-radius: 50px;
      }
    }

    &_left {
      justify-content: space-evenly;
    }
  }

  &__card {
    width: 100%;
    // width: 32vw;
  }

  &__tag {
    height: 24px;
    font-size: 11px;
    line-height: 24px;
    color: $primaryTextColor;
    background: #F4F8FF;
    border-radius: 26px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
}

@media only screen and (max-width: $small-screen) {
  .bill_summary {
    &__content {
      & label {
        font-size: 0.6em !important;
        line-height: 2.5em !important;
      }
      &_details {
        font-size: 1em !important;
      }
      &_right {
        right: 10px !important;
        & img {
          width: 52px !important;
          height: 52px !important;
          border-radius: 50px;
        }
      }
    }
  }
}
