.account {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border: none !important;
  background: $white;
  border-radius: 12px !important;
  max-width: 620px;

  & p {
    margin: 0;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20.16px;
    color: #2C264B;
  }

  @media only screen and (max-width: 450px) {
    padding: 24px 18px !important;
    //
  }
  .ref-code-input {
    > label {
      line-height: normal !important;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  h3 {
    font-family: $cg-bold;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    margin: 0;
    text-transform: capitalize;
  }
  .account-tab {
    display: flex;
    flex-direction: row;
    padding: 0px;
    height: 24px;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Edge
   */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, and Opera */
    }
    @media only screen and (max-width: 555px) {
      width: 100%;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $gs-medium;
      font-size: 11px;
      padding: 0 16px;
      line-height: 144%;
      text-align: center;
      background-color: #ffffff;
      border: 1px solid $primaryTextColor;
      color: $primaryTextColor;
      cursor: pointer;
      white-space: nowrap;
      // @media only screen and (max-width: 555px) {
      //   // font-size: 9px !important;
      //   padding: 0 8px !important;
      // }
      &:first-child {
        border-radius: 26px 0px 0px 26px;
        border-right: none !important;
      }
      &:nth-child(2) {
        border-right: none !important;
      }
      &:nth-child(3) {
        border-right: none !important;
      }
      &:last-child {
        border-radius: 0px 26px 26px 0px;
      }
    }
    button.active {
      background-color: $primaryTextColor;
      color: #ffffff;
    }
  }
  &__edit-profile {
    display: flex;
    flex-direction: column;
    gap: 32px;
    div {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 380px;
      @media only screen and (max-width: 483px) {
        width: 100% !important;
      }
      .profile-input {
        width: 100%;
        height: 48px !important;
        margin: 0 !important;
        padding: 16px 24px;
        background: #F4F8FF;
        border-radius: 26px;
        border: none;
        outline: none;
        &:hover {
          border: 1px solid $primaryTextColor;
        }
        &:active {
          border: 1px solid $primaryTextColor;
        }
        &::placeholder {
          font-family: "General Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 144%;
          color: #2c264b;
        }
      }
      p {
        font-family: $gs-regular;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        margin: 0;
      }
      &:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        .span-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
        p {
          width: fit-content;
          font-family: $gs-regular;
          text-align: center;
          color: #121020;
          margin: 0;
        }
        button {
          padding: 6px 16.8px;
        }
      }
    }
  }
  &__beneficiaries {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .recurrence-status {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 102.21px;
      justify-content: space-between;
      @media only screen and (max-width: 380px) {
        gap: 8px !important;
        justify-content: flex-end !important;
      }
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $gs-medium;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        padding: 0 16px;
        text-align: center;
        text-transform: capitalize;
        width: fit-content;
        border-radius: 26px;
        height: 24px;
        max-height: 24px !important;
        color: $primaryTextColor;
        background-color: #133E87;
        @media only screen and (max-width: 380px) {
          padding: 0 8px !important;
          height: 20px;
        }
      }
      span {
        &:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          cursor: pointer;
          svg {
            max-width: 12.92px !important;
            max-height: 12.92px !important;
            transform: rotate(270deg);
          }
        }
      }
    }
    .filter-container {
      width: 160px;
      max-width: 160px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &_label {
        font-family: $gs-regular;
        font-size: 11px;
        color: #000000;
        width: fit-content;
      }
      &_button {
        font-family: $gs-regular;
        font-size: 11px;
        color: $lceeBlack;
        display: flex;
        padding: 0 16px;
        height: 36px;
        max-height: 36px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 26px;
        border: 1px solid $primaryTextColor;
        background: #F4F8FF;
        cursor: pointer;
        svg {
          width: 9px !important;
          height: 9px !important;
        }
      }
    }
  }
  .beneficiaries_card {
    display: flex;
    flex-direction: column;

    &-inner {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 8px;
      @media only screen and (max-width: 450px) {
        padding: 16px 0 !important;
      }

      &.with-border-top {
        border-top: 0.5px solid #f0f0f0;
      }

      .beneficiary-label {
        display: flex;
        align-items: center;
        gap: 16px;
        @media only screen and (max-width: 450px) {
          gap: 8px !important;
        }
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #eae9ed;
        }
        &-amount {
          display: flex;
          flex-direction: column;
          .amount {
            font-family: $gs-semi-bold;
            font-style: normal;
            font-size: 14px;
            line-height: 144%;
            color: #161616;
            margin: 0;
            @media only screen and (max-width: 450px) {
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            @media only screen and (max-width: 350px) {
              max-width: 98px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          span {
            position: relative;
            display: flex;
            align-items: center;
            gap: 4px;
            p {
              font-family: $gs-regular;
              font-size: 10px;
              line-height: 14px;
              color: #696969;
              margin: 0;
              @media only screen and (max-width: 400px) {
                &:first-child {
                  max-width: 50px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              &:nth-child(2) {
                margin-top: -5px;
              }
              &.payment-method {
                text-transform: capitalize;
              }
            }
          }
        }
      }
      .beneficiary-status {
        display: flex;
        align-items: center;
        gap: 16px;
        @media only screen and (max-width: 350px) {
          gap: 8px !important;
        }
        &_edit {
          display: flex;
          height: 24px;
          padding: 0 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 26px;
          border: 1px solid $primaryTextColor;
          background-color: $white;
          cursor: pointer;
          @media only screen and (max-width: 450px) {
            padding: 0 8px !important;
            font-size: 12px !important;
          }
        }
        &_delete {
          display: flex;
          padding: 4px;
          justify-content: center;
          align-items: center;
          border-radius: 26px;
          background: #fdf0ee;
          border: none;
        }
      }
    }
  }

  .lcee-security__container {
    margin-top: 24px;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
 
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    div {
      margin: 0 !important;
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .ant-form-item-label {
      padding: 0 !important;
    }
    label {
      font-family: $gs-regular !important;

      color: #000000;
    }
  }
  .lcee-security__input input {
    width: 90%;
    height: 40px;
    padding: 16px 24px;
    background: #F4F8FF !important;
    border-radius: 26px;
    &::placeholder {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;

      color: #2c264b;
    }
  }

  .lcee-security__password input {
    width: 92.5%;
    height: 40px;
    padding: 16px 24px;
    background: #F4F8FF !important;
    border-radius: 26px;
  }
  .ant-input-affix-wrapper {
    height: 48px !important;
  }
  .lcee-security__button {
    width: 380px !important;
    height: 52px !important;
    @media only screen and (max-width: 487px) {
      width: 100% !important;
    }
  }

  .ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none !important;
  }

  .ant-input-affix-wrapper {
    border-radius: 26px !important;
    width: 380px !important;
    background-color: #F4F8FF !important;
    border: none !important;
    outline: none !important;

    @media only screen and (max-width: 487px) {
      width: 100% !important;
    }
  }
  &__upgrade-account {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .upgrade-account_message {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-self: stretch;
      border-radius: 8px;
      border: 0.5px solid #2b6ae8;
      background: #eaf1ff;
      font-family: $gs-regular;
      font-size: 11px !important;
      color: #14316b;
    }
    .upgrade-account_levels-container {
      display: flex;
      gap: 12px;
      align-items: center;
      .levels {
        display: flex;
        flex: 1;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 12px;
        border: 0.5px solid #b1b1b1;
        background: $white;
        &.active {
          border: 0.5px solid $primaryTextColor;
        }
        &-first {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: $gs-regular;
          font-weight: 600 !important;
          font-size: 14px;
        }
        &-second {
          display: flex;
          align-items: center;
          gap: 4px;
          font-family: $gs-regular;
          font-size: 10px;
          color: #56516f;
          b {
            font-family: $cg-bold;
            color: $lceeBlack;
            font-size: 18px;
            font-weight: 800 !important;
          }
        }
        &-button {
          display: flex;
          height: 24px;
          padding: 0 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 26px;
          border: 1px solid $primaryTextColor;
          background-color: $white;
          font-family: $gs-regular;
          font-size: 11px;
          font-weight: 600 !important;
          color: $primaryTextColor;
          cursor: pointer;
          &.opacity {
            opacity: 0.30000001192092896;
            cursor: not-allowed !important;
          }
        }
        &-last {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: 0 !important;
          &_paragraph {
            font-family: $gs-regular;
            font-size: 11px !important;
            font-weight: 600 !important;
          }
          &_list {
            font-family: $gs-regular;
            font-size: 11px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #56516f;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
    .account{

      &__edit-profile {
        display: flex;
        flex-direction: column;
        gap: 32px;
        div {
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 380px;
          .profile-input {
            width: 100%;
            height: 48px !important;
            margin: 0 !important;
            padding: 16px 24px;
            background: #F4F8FF;
            border-radius: 26px;
            border: none;
            outline: none;
            &:hover {
              border: 1px solid $primaryTextColor;
            }
            &:active {
              border: 1px solid $primaryTextColor;
            }
            &::placeholder {
              font-family: "General Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 144%;
              color: #2c264b;
            }
          }

          .phone-number {
            width: 75% !important;
          }

          .email-input{
            width: 75% !important;

          }

          // refer-code responsiveness
          .ant-input-affix-wrapper{
            width: 76% !important;
          }

          // save change responsivenesss
          .ant-btn{
            width: 80% !important;
          }


          p {
            font-family: $gs-regular;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            margin: 0;
          }
          &:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            .span-container {
              display: flex;
              flex-direction: column;
              gap: 16px;
              width: 150px;
            }
            p {
              width: fit-content;
              font-family: $gs-regular;
              text-align: center;
              color: #121020;
              margin: 0;
            }
            button {
              padding: 6px 16.8px;
            }
          }
        }
      }
    }
}

@media screen and (max-width: 500px) {
    .account{

      &__edit-profile {
        div {
          .profile-input {
            width: 100%;
            height: 48px !important;
            margin: 0 !important;
            padding: 16px 24px;
            background: #F4F8FF;
            border-radius: 26px;
            border: none;
            outline: none;
            &:hover {
              border: 1px solid $primaryTextColor;
            }
            &:active {
              border: 1px solid $primaryTextColor;
            }
            &::placeholder {
              font-family: "General Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 144%;
              color: #2c264b;
            }
          }

          .phone-number {
            width: 89% !important;
          }

          .email-input{
            width: 89% !important;

          }

          // refer-code responsiveness
          .ant-input-affix-wrapper{
            width: 89% !important;
          }

          // save change responsivenesss
          .ant-btn{
            width: 89% !important;
          }


          p {
            font-family: $gs-regular;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            margin: 0;
          }
        }
      }
    }
}

.update-transaction-pin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 600px) {
    .account{

      &__edit-profile {
        div {
          .profile-input {
            width: 100%;
            height: 48px !important;
            margin: 0 !important;
            padding: 16px 24px;
            background: #F4F8FF;
            border-radius: 26px;
            border: none;
            outline: none;
            &:hover {
              border: 1px solid $primaryTextColor;
            }
            &:active {
              border: 1px solid $primaryTextColor;
            }
            &::placeholder {
              font-family: "General Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 144%;
              color: #2c264b;
            }
          }

          .phone-number {
            width: 100% !important;
          }

          .email-input{
            width: 100% !important;

          }

          // refer-code responsiveness
          .ant-input-affix-wrapper{
            width: 100% !important;
          }

          // save change responsivenesss
          .ant-btn{
            width: 100% !important;
          }


          p {
            font-family: $gs-regular;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            margin: 0;
          }
        }
      }
    }

}
