.sp-status {
    display: inline-block;
    width: auto;
    text-align: center;
    font-size: 11px;
    line-height: 15.84px;
    font-weight: 500;
    border-radius: 26px;
    padding: 8px 16px;
  
    &.primary {
      color: #133E87;
      background-color: #F4F8FF;
    }
  
    &.success {
      color: #28a745;
      background-color: rgba(40, 167, 69, 0.1);
    }
  
    &.danger {
      color: #dc3545;
      background-color: rgba(220, 53, 69, 0.1);
    }
  
    &.info {
      color: #17a2b8;
      background-color: rgba(23, 162, 184, 0.1);
    }
  
    &.warning {
      color: #ffc107;
      background-color: rgba(255, 193, 7, 0.1);
    }
  }
  