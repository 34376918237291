.privacyPolicy {
  width: 100%;
  padding: 50px 11vw;
  margin-top: -24px;
  &__inner {
    position: relative;
    display: flex;
    gap: 70px;
    &-article,
    .all-articles {
      display: flex;
      flex-direction: column;
      gap: 16px;
      h1 {
        font-family: $cg-bold;
        font-style: normal;
        font-size: 36px;
        line-height: 45px;
        color: #000000;
        margin: 0;
        margin-bottom: 8px;
      }
      article {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        h3 {
          font-family: $cg-bold;
          font-style: normal;
          font-size: 22px;
          line-height: 27px;
          color: #000000;
          margin: 0;
        }
        p {
          font-family: $gs-regular;
          font-style: normal;
          // font-size: 14px;
          font-size: 0.95em;
          line-height: 160%;
          color: #000000;
          margin: 0;
          span {
            color: $primaryTextColor;
            font-size: 0.95em !important;
          }
        }
        .ul,
        .ol {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin: 0;
        }
        .ul {
          list-style-type: disc !important;
        }
      }
    }
    &-aside {
      // position: fixed;
      // right: 9.8%;
      // max-width: 218.42px !important;
      padding: 24px 16px 32px 16px;
      width: fit-content;
      height: fit-content;
      background: #ffffff;
      border: 0.5px solid #b1b1b1;
      border-radius: 12px;
      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        h6 {
          font-family: $gs-medium;
          font-style: normal;
          // font-size: 14px;
          font-size: 0.95em;
          line-height: 144%;
          color: #000000;
          margin: 0;
        }
        a {
          font-family: $gs-medium;
          font-style: normal;
          // font-size: 14px;
          font-size: 0.95em;
          line-height: 144%;
          color: $primaryTextColor;
          margin: 0;
          &:hover {
            color: #133E87;
          }
        }
      }
    }
  }
}

//responsive styles

@media screen and (max-width: 669px) {
  .privacyPolicy {
    &__inner {
      flex-direction: column-reverse;
    }
  }
}
