.tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 8px;
  height: 24px;
  background: #F4F8FF;
  border-radius: 26px;
  cursor: pointer;

  & span {
    font-family: $gs-medium;
    font-style: normal;
    font-size: 11px;
    line-height: 144%;
    text-align: center;
    color: #133E87;
  }

  &:hover {
    background: rgba(160, 105, 233, 0.1);
  }
}

@media only screen and (max-width: $small-screen) {
  .tag {
    padding: 10px !important;

    & span {
      font-size: 0.65em !important;
    }
  }
}
