

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
    border    : none;
    box-shadow: none;
    z-index   : 1;
}

.sp-input {
    height          : 48px;
    border-radius   : 8px;
    border          : none;
    padding         : 16px 24px;
    background-color: #F4F8FF;

    &:focus, &.ant-input-affix-wrapper-focused  {
        border: 1px solid #133E87;
        box-shadow: 0 0 0 1px #133E87 !important;
    }

    &__spin {
        color: #133E87;
    }

    &.sp-outlined {
        border: 2px solid #F4F8FF;
    }
}
