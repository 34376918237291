.amount_card {
  &__wrapper {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 24px;
    width: max-content;
    height: 40px;

    border: 1px $primaryTextColor solid;
    border-radius: 26px;
    cursor: pointer;

    &:hover {
      background: #133E87;
      & label {
        color: $white;
      }
    }

    &_selected {
      background: #133E87;
      & label {
        color: $white !important;
      }
    }
  }

  &__label {
    color: $lceeBlack !important;
  }

  & label {
    color: $primaryTextColor;
    cursor: pointer;
  }
}
