.input {
  // flex-direction: row;
  // align-items: center;
  // padding: 15px 24px;
  padding-inline: 15px 20px;
  gap: 8px;
  width: "100%";
  height: 49px;
  background: #F4F8FF;
  border-radius: 26px;
  font-family: "General Sans";
  margin-top: 10px;
  // padding-right: 45px;

  &_wrapper {
    // max-width: 380px;
    flex-direction: column;
    position: relative;

    & > label {
      text-align: left;
    }
  }

  &__icon_right {
    position: absolute;
    right: 15px;
    top: 24px;
  }

  &__bottom_label {
    font-family: $gs-medium;
    font-size: 11px;
    color: $lceeGreen;
    text-transform: uppercase;
    margin-top: 4px;
  }
}

.masked {
  &__input {
    .ant-input-disabled,
    .ant-input[disabled] {
      color: inherit !important;
    }
  }
}

.suffix-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
