.dashboard {
  // padding: 0 50px;

  &__layout {
    background: $bgColor;
    padding: 0;
    margin-top: -0.5rem;
  }
  &__content {
    min-height: 280px;
    margin-left: 30px;
  }
  &__side {
    background: $white;
    min-width: 280px !important;
  }
  &__menu {
    // color: red;
    &_item {
      padding-block: 32px;
      span {
        height: 23px;
      }
      ion-icon {
        float: right;
        height: 100%;
        font-size: 18px;
      }
    }
  }
}

.card {
  background: $white;
  min-width: 280px !important;
  padding: 15px 18px;
  border-radius: 8px;
}

.service-img-icon {
  width: 24px;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(80%);

  &.active, &:active {
    filter: invert(23%) sepia(92%) saturate(4452%) hue-rotate(194deg) brightness(65%) contrast(130%);
  }
}

