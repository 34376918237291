.ant-btn.ant-btn-round {
  border-radius: 20px !important;
}

.ant-modal.success-modal {
  width: 435px !important;
}

.ant-modal-mask {
  background-color: rgba(244, 248, 255, 0.09) !important;
}

// .ant-modal-content {
//   // padding: 60px 60px !important;
// }

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  height: 56px;
  line-height: 40px;
  padding-inline: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 0px;
  margin-block: 0px;
  width: auto;
  border-radius: 0;
}

.ant-menu-inline .ant-menu-item:first-child,
.ant-menu-vertical .ant-menu-item:first-child,
.ant-menu-inline .ant-menu-submenu-title:first-child,
.ant-menu-vertical .ant-menu-submenu-title:first-child {
  border-top-right-radius: $borderRadius;
  border-top-left-radius: $borderRadius;
}

.sp-drawer .ant-menu-inline .ant-menu-item {
  border-radius: $borderRadius;
}

.ant-menu-inline .ant-menu-item:last-child,
.ant-menu-vertical .ant-menu-item:last-child,
.ant-menu-inline .ant-menu-submenu-title:last-child,
.ant-menu-vertical .ant-menu-submenu-title:last-child {
  border-bottom-right-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #F4F8FF;
}

.ant-layout-sider-children {
  background: $bgColor !important;
}

.ant-menu-light {
  border-radius: 10px;
}

.ant-menu-item-selected .ant-menu-title-content {
  font-family: $gs-semi-bold;
  color: $primaryTextColor;
  // font-weight: bold;
}

.ant-menu-title-content {
  // margin-inline-start: 10px !important;
  // opacity: 1;
  // transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
  //   color 0.3s;
  text-transform: capitalize;
}


.ant-dropdown {
  z-index: 10000;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primaryTextColor;
  border-color: $primaryTextColor;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: $primaryTextColor !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: $primaryTextColor !important;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $primaryTextColor;
}

.ant-checkbox-checked:after {
  border: 2px solid $primaryTextColor;
}

.ant-checkbox-checked:after:hover {
  border: 2px solid $primaryTextColor;
}

.ant-input {
  background: #F4F8FF !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

.ant-layout .ant-layout-sider-children {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.ant-layout .ant-layout-footer {
  max-width: 87.5vw;
  width: 100%;
  align-self: center;
  padding: 8px;
  margin-top: 32px;

  & > * {
    flex: 1;
  }

  a {
    font-size: 12px;
  }

  ion-icon {
    font-size: 18px;
  }

  @media(max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
 
}

.sp-dashboard {
  .ant-layout .ant-layout-footer {
    max-width: 62vw;
    
    @media (max-width: 600px) {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

// grid
@media (max-width: 600px) {
  .ant-col-8 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-layout .ant-layout-footer {
    font-size: 12px;
    max-width: 100vw;
    width: 100%;
    padding: 0px 16px;
  }
}


.ant-layout .ant-layout-header {
  padding-inline: 0 !important;
}

.ant-message-custom-content {
  span {
    font-family: $gs-medium;
  }
}

//Result component

.ant-result-title {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 43.2px;
}

.ant-result-subtitle {
  max-width: 578px;
  text-align: center;
  margin: 0 auto;
}

